<template>
 <div class="menuList">
  <el-menu
  :default-active="currentRouter"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    @open="handleOpen"
    @close="handleClose"
  >
  <!-- 组 -->
  <template v-for="(item,index) in menulist">
  <!-- 路由 -->
    <el-menu-item :index="item.path" v-if="item.path" :key="index"  @click="goPage(item)">
       <el-icon> <component :is="item.icon" style="width: 18px;height: 18px;"></component></el-icon>
      <template #title>{{ item.title }}</template>
    </el-menu-item>
<!-- 路由组 -->
<el-sub-menu v-if="!item.path" :index="index" >
      <template #title >
        <el-icon> <component :is="item.icon" style="width: 18px;height: 18px;"></component></el-icon>
        <span>{{item.title }}</span>
      </template>
      <el-menu-item  :index="citem.path" v-for="(citem,cindex) in item.children" :key="cindex"  @click="goPage(citem)">
       <!-- <el-icon> <component :is="citem.icon" style="width: 18px;height: 18px;"></component></el-icon> -->
      <template #title>{{ citem.title }}</template>
    </el-menu-item>


    </el-sub-menu>
  </template>



  </el-menu>

 </div>
</template>

<script setup>
// import router from '@/router'
import {computed,onMounted} from "vue"
import {useStore} from "vuex";
const store = useStore()
const isCollapse = computed(() => store.state.sysModel.isCollapse)
import { useRouter } from 'vue-router';
const router= useRouter()
const currentRouter = computed(()=>store.state.sysModel.currentRouter)

const handleOpen = () => {
  // console.log(key, keyPath)
}
const handleClose = () => {
  // console.log(key, keyPath)
}
const menulist = computed(()=>{
  const list = store.state.loginModel.menulist
  const dict = {firts:[]}
  let menu = []
  list.forEach(item=>{
    console.log(33,item)
    if(item.group && item.groupName){
      if(dict[item.group]){
        dict[item.group].push(item)
      }else{
        dict[item.group]=[]
        dict[item.group].push(item)
      }
    }else{
      dict.firts.push(item)
    }
  })

 for(let key in dict){
  if(dict[key] && dict[key].length>0){
    if(key==="firts"){
      menu = menu.concat(dict[key])
      console.log(99,dict[key])
    }else{
      menu.push({name:key,children:dict[key],title:dict[key][0].groupName,icon:dict[key][0].icon})
    }

   }

 }
 console.log(55,menu)
   return menu

})
// console.log('menulist',menulist)
// 跳转页面
const goPage = (item)=>{
  const path = item.path
      router.push({
          path: path
      })
}
onMounted(()=>{
  // 跳至第一个页面
  // goPage(menulist.value[0])
})
</script>

<style scoped>
 .menuList{
   width: 100%;
 }
 .el-menu-vertical-demo:not(.el-menu--collapse) {
   width: 200px;
   min-height: 400px;
 }
</style>
