import { createStore } from 'vuex'
import loginModel from "./login"
import sysModel from "./sys"
// 本地化存储
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    loginModel: loginModel,
    sysModel:sysModel,
  },
  // 设置本地化存储
  plugins: [
    // 默认储存在localstorage
    createPersistedState({
      // 本地储存名
      key: 'gaoyayangcangxunjian',
      // 指定数据进行本地存储
      paths: ['loginModel.login','loginModel.userInfo','loginModel.auth',]
    })
  ],
})
