import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "bootstrap/dist/css/bootstrap.min.css"
import locale from 'element-plus/es/locale/lang/zh-cn' // 汉化

import '@/assets/css/index.scss'
const app = createApp(App)
app.use(ElementPlus,{locale})

app.use(store).use(router).use(ElementPlus).mount('#app')
// 注册全局字体
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
