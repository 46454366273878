import { createRouter, createWebHashHistory  } from 'vue-router'
import Layout from '@/baseLayout/LayoutPage'
import store from '@/store'
import {ElMessage} from "element-plus";

const routes = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName:"about" */ "../views/home/homeview"),
    meta: {
      title: "数据统计与分析", // 标题
      KeepAlive: false, // 是否显示缓存
      showTitle: true, // 是否在菜单上显示
      icon:"TrendCharts"
    }
  },
  {
    path: '/main',
    name: 'main',
    meta: {title: '首页'},
    component: Layout,
    beforeEnter:(to,from,next)=>{
      const menue = getUserMenue()
      if(to.path==="/main"){
        // next({path:menue[1].path,replace:true})
        next({path:"/projects",replace:true})
      }else{
        next()
      }
    },
    children:[
      {
        path: '/manudetail',
        name: 'manuDetailPage',
        meta: {title: '手册编辑/增加', keepalive: false, showTitle: false,icon:"Setting",showTop:true},
        component:  () => import(/* webpackChunkName: "about" */ '../views/manuDetailPage'),

      },
      //  资料管理
      {
        path: '/suppliermaterials',
        name: 'suppliermaterials',
        meta: {title: '供应商（来料）资料', keepalive: false, showTitle: true,icon:"Document",group:"materials",groupName:"资料管理"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/suppliermaterials'),
      },
      {
        path: '/innerProjectMaterials',
        name: 'innerProjectMaterials',
        meta: {title: '内部（厂内）资料', keepalive: false, showTitle: true,icon:"Document",group:"materials",groupName:"资料管理"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/innerProjectMaterials'),
      },
      //  项目管理
      {
        path: '/projects',
        name: 'projects',
        meta: {title: '项目管理', keepalive: false, showTitle: true,icon:"Folder"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/projectPage'),
      },
      {
        path: '/projectDetail',
        name: 'projectDetailPage',
        meta: {title: '项目详情', keepalive: false, showTitle: false,showTop:true,icon:"Folder"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/projectDetailPage'),
      },
      //  来料管理
      {
        path: '/coming',
        name: 'coming',
        meta: {title: '来料管理', keepalive: false, showTitle: true,icon:"Opportunity"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/cominglist'),
      },
      //  过程检测
      {
        path: '/processResult',
        name: 'processResult',
        meta: {title: '过程检测', keepalive: false, showTitle: true,icon:"Operation"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/processResultPage'),
      },
      //  供应商管理
      {
        path: '/supplier',
        name: 'supplier',
        meta: {title: '供应商管理', keepalive: false, showTitle: true,icon:"OfficeBuilding"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/supplierPage'),
      },
      {
        path: '/supplierDetail',
        name: 'supplierDetailPage',
        meta: {title: '问题详情', keepalive: false, showTitle: false,icon:"Setting",showTop:true},
        component:  () => import(/* webpackChunkName: "about" */ '../views/supplierDetailPage'),
      },
      //  基础管理：1，账号管理
      {
        path: '/users',
        name: 'usersPage',
        meta: {title: '账号管理', keepalive: false, showTitle: true,icon:"Setting",group:"base",groupName:"基础管理"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/usersPage'),
        // beforeEnter:(to,from,next)=>{
        //   const userInfo = store.getters['loginModel/getUserInfo']
        //   if(userInfo.isSuperAdmin){
        //     next() // 只有超级管理员才有权限进入该模块
        //   }else{
        //
        //   }
        //  console.log(3333,userInfo)
        // },
      },
      {
        path: '/productClass',
        name: 'productClass',
        meta: {title: '产品机型', keepalive: false, showTitle: true,icon:"Setting",group:"base",groupName:"基础管理"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/productClass'),
      },
      {
        path: '/docClass',
        name: 'docClass',
        meta: {title: '资料类别', keepalive: false, showTitle: true,icon:"Setting",group:"base",groupName:"基础管理"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/docClass'),
      },
      {
        path: '/docTemplate',
        name: 'docTemplate',
        meta: {title: '模版管理', keepalive: false, showTitle: true,icon:"Setting",group:"base",groupName:"基础管理"},
        component:  () => import(/* webpackChunkName: "about" */ '../views/docTemplate'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue')
  },
  {
    path: '/:path(.*)',
    name: '404',
    meta: {},
    component: () => import(/* webpackChunkName: "about" */ '../views/ErrorPage.vue')
  }
]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// 增加路由守卫
const changeTitle = (to) => {
  if (to.meta && (to.meta.showTitle||to.meta.showTop)) { // 左侧的菜单全部显示，属性为showTop的菜单也全部显示
    store.commit("sysModel/changeTitle", to.meta.title)
  }else{
    store.commit("sysModel/changeTitle", "")
  }
}
router.beforeEach((to, from, next) => {
  const isLogin = store.getters['loginModel/getLoginState']
  console.log("isLogin",isLogin)
  // const userInfo = store.getters['loginModel/getUserInfo']

  if (isLogin) { // 如果已经登录
    // 判断权限是否相符
    // if(to.meta.role && (to.meta.role!==userInfo.role)){ // 如果访问的带权限的页面，而且访问的页面不属于当前页面权限
    //   ElMessage.error('您无权访问当前页面')
    //   store.commit("loginModel/loginOut")
    //   // return next({path:'/'})
    // }
    // 判断是否登录
    if (to.name === 'login') {
      next({path:'/'}) // 如果已经登录了，跳转到登录页的时候，登录到首页
    } else {
      next()
    }
    changeTitle(to)
    store.commit("sysModel/currentRouter", to.path)
  } else { // 如果没登录，跳转到登录页面，登录页不显示进度条
    // next()
    if (to.name === 'login') {
      next()
    } else {
      next({path: "/login"})
    }

  }
})
// 根据用户信息，获取用户的菜单列表  meta: {title: '设备列表', keepalive: false, showTitle: true,icon:"Setting"},
export function getUserMenue(){
  let menus = [];
  // let filterMenue = [];
  // 获取菜单列表
  const user = store.getters['loginModel/getUserInfo'] || {}
  if(!user){
    ElMessage.error('无法获取该账户权限')
    store.commit("loginModel/loginOut")
  }
  // 筛选菜单
  const dealRouter = (routes)=>{
    const dict =
    routes.forEach(items=>{
      if(items && items.meta){
        if(items.meta.showTitle){
          menus.push({...items.meta,title:items.meta.title,path:items.path,name:items.name,icon:items.meta.icon})
        }
      }
      if(items.children){
        dealRouter(items.children)
      }
    })
  }
  dealRouter(routes)


  // 过滤菜单 根据是否是admin
  // filterMenue = menus.filter(v=>(!v.role || v.role===admin))
  // todo menus 改 filterMenue
  store.commit('loginModel/resetMenus',menus)
  return menus
}

export default router
