export const validateForm = (ruleFormRef)=>{
    if (!ruleFormRef){
        return  Promise.resolve(false)
    }
    return new Promise((resolve) => {
        ruleFormRef.validate((valid)=>{
            if (valid) {
                resolve(true)
            }
        }).then(()=>{
            resolve(false)
        })
    })
}
