import request from "@/utils/request";
// 登录
function login(data){
    return request({
        url:'/login',
        data:data,
        method:'post'
    })
}

// 获取当前用户登录信息
function currentUser(data){
    return request({
        url:'/admin/currentUser',
        data:data,
        method:'get'
    })
}

// 修改管理员密码
function changeAdminPassword(data){
    return request({
        url:'/admin/changeAdminPassword',
        data:data,
        method:'post'
    })
}


export {
    login,
    currentUser,
    changeAdminPassword,
}
