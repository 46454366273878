<!-- 修改自身的密码-->
<template>
  <el-dialog
      v-model="dialogVisible"
      :title="'修改密码'"
      top="20px"
      width="400px"
      :close-on-click-modal="false"
      :append-to-body="true"
      :show-close="true"
      :before-close="handleClose">
    <div class="dialog-item1">
      <el-form :model="form" :label-position="'top'" label-width="100px" :rules="rules" class="el-form-box"
               ref="ruleFormRef1">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="输入密码：" prop="password">
              <el-input v-model="form.password" placeholder="" type="password"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="密码确认:" prop="confirmPassword">
              <el-input v-model="form.confirmPassword" type="password" placeholder=""/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="save(ruleFormRef1,ruleFormRef2)"
                   :loading = "loading"
        >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>


import {defineProps, reactive, ref, defineEmits, onMounted} from "vue";
const loading = ref(false)
const emits = defineEmits(['close', 'success'])


const dialogVisible = ref(true)

import {ElMessage} from "element-plus";
import {useStore} from 'vuex'
import {changeAdminPassword} from "@/api/login";
import {validateForm} from "@/utils/formValidate";
import {updateUser} from "@/api/user";
const store = useStore()
const ruleFormRef1 = ref()

const props = defineProps({
  adminId: {
    default: {},
  },
  item:{
    default:{}
  }
})
var form = reactive({
  id: null,
  loginName: null,
  password: null,
  confirmPassword: null,
})
const rules = {
  password: [{required: true, message: '请输入密码', trigger: ['blur', 'change']}],
  confirmPassword: [{required: true, message: '请再次确认密码', trigger: ['blur', 'change']}],
}


const handleClose = () => {
  dialogVisible.value = false
  emits("close", true)
}
const save = async (ruleFormRef1) => {
  if (!ruleFormRef1) return
  const isvalidate = await validateForm(ruleFormRef1)
  if (!isvalidate) {
    return
  }
  // 修改本账户
  if(props.item && props.item.ID){
//修改列表账户
    if(form.password !==form.confirmPassword){
      return  ElMessage.warning("两次密码不一致")
    }
    const params = {
      ...props.item,
      password:form.password,
    }
    const res = await updateUser(params)
    if(res.success){
      ElMessage.success("修改成功")
      emits("close", true)
    }else{
      ElMessage.warning("修改失败")
    }
  }else{
    //修改列表账户
    const params = {
      password:form.password,
      confirmPassword:form.confirmPassword
    }
    const res = await changeAdminPassword(params)
    loading.value = false
    if(res.success){
      ElMessage.success("修改成功")
      setTimeout(()=>{
        emits("success")
      },1000)
  }else{
      ElMessage.warning(res.msg||"修改失败")
    }}

}

// 初始化钩子
onMounted(() => {
  initFormData() // 初始化表单数据
})


// 初始化数据
const initFormData = ()=>{
  if(props.item && props.item.ID){ // 修改
    // 基础信息
    form.id = props.item.ID
    form.loginName = props.item.LoginName
  }
}
</script>

<style scoped lang="scss">
.dialog-item1 {
  width: 100%;
  height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
}


</style>
