<template>

  <router-view></router-view>


</template>
<script setup>
// 解决el-table 报错
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _= window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _{
  constructor(callback) {
    callback = debounce(callback,20)
    super(callback);
  }
}
</script>
<style lang="scss">
// 去掉滚动条
//::-webkit-scrollbar{
//  display: none;
//  width: 0 ;
//  height: 0 ;
//  -webkit-appearance: none;
//  background: transparent;
//}

.map-32-label {
  font-size: 10px;
  color: #fff;
}

.map-32-label-new {
  font-size: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    > img {
      width: 30px !important;
      height: 30px !important;
    }

    > div {
      font-size: 16px !important;
    }
  }

  div {
    color: white;
    font-weight: bold;
  }
}
.deviceItem-1-1 {
  >div {
    color: hwb(207 25% 11%)
  }
}

.deviceItem-1-2,
.deviceItem-2-2 {
  >div {
    color: hwb(31 17% 7%)
  }
}

.deviceItem-1-3,
.deviceItem-2-3 {
  >div {
    color: hwb(0 17% 7%)
  }
}

.deviceItem-2-1 {
  >div {
    color: hwb(145 5% 49%)
  }
}

</style>
