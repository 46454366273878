import request from "@/utils/request";
// 列表
export function getUserList(data){
    // return {success:true,data:[{},{}]}
    return request({
        url:'/admin/getAdminUsers',
        data:data,
        method:'get'
    })
}


// 修改密码
export function changePw(data){
    return request({
        url:'/admin/changeAdminPassword',
        data:data,
        method:'post'
    })
}
// 修改
export function updateUser(data){
    return request({
        url:'/admin/updateAdminUser',
        data:data,
        method:'post'
    })
}
// 添加
export function addUser(data){
    return request({
        url:'/admin/addAdminUser',
        data:data,
        method:'post'
    })
}
// 删除
export function deleteUser(data){

    return request({
        url:'/admin/removeAdminUser',
        data:data,
        method:'post'
    })
}
// 更新
export function updateServiceUser(data){
    return request({
        url:'/admin/updateWxUser',
        data:data,
        method:'post'
    })
}
